var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.columns.length
    ? _c("table-page", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoaded,
            expression: "isLoaded",
          },
        ],
        ref: "curlTablePage",
        staticClass: "curlTable",
        attrs: {
          tableName: _vm.$options.name,
          columns: _vm.columns,
          filterModel: _vm.filterModel,
          requestFunction: _vm.requestFunction,
          defaultOrderBy: { column: "CurlName", ascending: 1 },
          showActionsColumn: false,
          modelId: "Id",
          headerTitle: "urlcatalog2775",
          tooltipMsg: "manualforurlcat",
        },
        on: {
          addNewRecord: function ($event) {
            return _vm.redirectToDetail()
          },
          filterChanged: function ($event) {
            return _vm.handleFilter($event)
          },
          removeFilters: function ($event) {
            return _vm.removeAllFilters($event)
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "CurlName",
              fn: function (list) {
                return [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: list.row.CurlDomain + list.row.CurlName,
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(list.row.CurlName))]
                  ),
                ]
              },
            },
            {
              key: "CurlHaveSkAdvertisement",
              fn: function (list) {
                return [
                  list.row.CurlHaveSkAdvertisement
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { variant: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.changeSkAdvertisement(list.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("advertising")))]
                      )
                    : _vm._e(),
                  !list.row.CurlHaveSkAdvertisement
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "cursor-pointer gray-icon",
                          attrs: { variant: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.changeSkAdvertisement(list.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("noadvertising")))]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "CurlHaveCzAdvertisement",
              fn: function (list) {
                return [
                  list.row.CurlHaveCzAdvertisement
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { variant: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.changeCzAdvertisement(list.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("advertising")))]
                      )
                    : _vm._e(),
                  !list.row.CurlHaveCzAdvertisement
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "cursor-pointer gray-icon",
                          attrs: { variant: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.changeCzAdvertisement(list.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("noadvertising")))]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "CurlHavePlAdvertisement",
              fn: function (list) {
                return [
                  list.row.CurlHavePlAdvertisement
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { variant: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.changePlAdvertisement(list.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("advertising")))]
                      )
                    : _vm._e(),
                  !list.row.CurlHavePlAdvertisement
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "cursor-pointer gray-icon",
                          attrs: { variant: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.changePlAdvertisement(list.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("noadvertising")))]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "CurlHaveHuAdvertisement",
              fn: function (list) {
                return [
                  list.row.CurlHaveHuAdvertisement
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { variant: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.changeHuAdvertisement(list.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("advertising")))]
                      )
                    : _vm._e(),
                  !list.row.CurlHaveHuAdvertisement
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "cursor-pointer gray-icon",
                          attrs: { variant: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.changeHuAdvertisement(list.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("noadvertising")))]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "CurlHaveDeAdvertisement",
              fn: function (list) {
                return [
                  list.row.CurlHaveDeAdvertisement
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { variant: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.changeDeAdvertisement(list.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("advertising")))]
                      )
                    : _vm._e(),
                  !list.row.CurlHaveDeAdvertisement
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "cursor-pointer gray-icon",
                          attrs: { variant: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.changeDeAdvertisement(list.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("noadvertising")))]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "CurlType",
              fn: function (list) {
                return [_vm._v(_vm._s(_vm.formatType(list.row.CurlType)))]
              },
            },
            {
              key: "CurlHaveEnAdvertisement",
              fn: function (list) {
                return [
                  list.row.CurlHaveEnAdvertisement
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { variant: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.changeEnAdvertisement(list.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("advertising")))]
                      )
                    : _vm._e(),
                  !list.row.CurlHaveEnAdvertisement
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "cursor-pointer gray-icon",
                          attrs: { variant: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.changeEnAdvertisement(list.row)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("noadvertising")))]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "SeoArticlesSkCount",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-between" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(list.row.SeoArticlesSkCount)),
                      ]),
                      _c("label", [_vm._v(" ")]),
                      list.row.SetSkToWrite > list.row.SeoArticlesSkCount
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "cursor-pointer",
                              attrs: { variant: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.skStateDone(list.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("fortyping")))]
                          )
                        : _vm._e(),
                      list.row.SetSkToWrite <= list.row.SeoArticlesSkCount
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "cursor-pointer gray-icon",
                              attrs: { variant: "secondary" },
                              on: {
                                click: function ($event) {
                                  return _vm.skStateSet(list.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("2781")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "SeoArticlesCzCount",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-between" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(list.row.SeoArticlesCzCount)),
                      ]),
                      _c("label", [_vm._v(" ")]),
                      list.row.SetCzToWrite > list.row.SeoArticlesCzCount
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "cursor-pointer",
                              attrs: { variant: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.czStateDone(list.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("fortyping")))]
                          )
                        : _vm._e(),
                      list.row.SetCzToWrite <= list.row.SeoArticlesCzCount
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "cursor-pointer gray-icon",
                              attrs: { variant: "secondary" },
                              on: {
                                click: function ($event) {
                                  return _vm.czStateSet(list.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("2781")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "SeoArticlesPlCount",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-between" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(list.row.SeoArticlesPlCount)),
                      ]),
                      _c("label", [_vm._v(" ")]),
                      list.row.SetPlToWrite > list.row.SeoArticlesPlCount
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "cursor-pointer",
                              attrs: { variant: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.plStateDone(list.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("fortyping")))]
                          )
                        : _vm._e(),
                      list.row.SetPlToWrite <= list.row.SeoArticlesPlCount
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "cursor-pointer gray-icon",
                              attrs: { variant: "secondary" },
                              on: {
                                click: function ($event) {
                                  return _vm.plStateSet(list.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("2781")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "SeoArticlesHuCount",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-between" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(list.row.SeoArticlesHuCount)),
                      ]),
                      _c("label", [_vm._v(" ")]),
                      list.row.SetHuToWrite > list.row.SeoArticlesHuCount
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "cursor-pointer",
                              attrs: { variant: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.huStateDone(list.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("fortyping")))]
                          )
                        : _vm._e(),
                      list.row.SetHuToWrite <= list.row.SeoArticlesHuCount
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "cursor-pointer gray-icon",
                              attrs: { variant: "secondary" },
                              on: {
                                click: function ($event) {
                                  return _vm.huStateSet(list.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("2781")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "SeoArticlesDeCount",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-between" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(list.row.SeoArticlesDeCount)),
                      ]),
                      _c("label", [_vm._v(" ")]),
                      list.row.SetDeToWrite > list.row.SeoArticlesDeCount
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "cursor-pointer",
                              attrs: { variant: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.deStateDone(list.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("fortyping")))]
                          )
                        : _vm._e(),
                      list.row.SetDeToWrite <= list.row.SeoArticlesDeCount
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "cursor-pointer gray-icon",
                              attrs: { variant: "secondary" },
                              on: {
                                click: function ($event) {
                                  return _vm.deStateSet(list.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("2781")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "SeoArticlesEnCount",
              fn: function (list) {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-between" },
                    [
                      _c("label", [
                        _vm._v(_vm._s(list.row.SeoArticlesEnCount)),
                      ]),
                      _c("label", [_vm._v(" ")]),
                      list.row.SetEnToWrite > list.row.SeoArticlesEnCount
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "cursor-pointer",
                              attrs: { variant: "success" },
                              on: {
                                click: function ($event) {
                                  return _vm.enStateDone(list.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("fortyping")))]
                          )
                        : _vm._e(),
                      list.row.SetEnToWrite <= list.row.SeoArticlesEnCount
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "cursor-pointer gray-icon",
                              attrs: { variant: "secondary" },
                              on: {
                                click: function ($event) {
                                  return _vm.enStateSet(list.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("2781")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "CountryId",
              fn: function (list) {
                return [
                  _c("country-flag", {
                    attrs: { countryId: Number(list.row.CountryId) },
                  }),
                ]
              },
            },
          ],
          null,
          false,
          198019809
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }