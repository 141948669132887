<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		ref="curlTablePage"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'CurlName', ascending: 1 }"
		:showActionsColumn="false"
		modelId="Id"
		headerTitle="urlcatalog2775"
		tooltipMsg="manualforurlcat"
		@addNewRecord="redirectToDetail()"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="curlTable"
	>
		<template #CurlName="list">
			<a :href="list.row.CurlDomain + list.row.CurlName" target="_blank">{{ list.row.CurlName }}</a>
		</template>

		<template #CurlHaveSkAdvertisement="list">
			<b-badge
				@click="changeSkAdvertisement(list.row)"
				class="cursor-pointer"
				v-if="list.row.CurlHaveSkAdvertisement"
				variant="success"
				>{{ $t('advertising') }}</b-badge
			>

			<b-badge
				@click="changeSkAdvertisement(list.row)"
				class="cursor-pointer gray-icon"
				v-if="!list.row.CurlHaveSkAdvertisement"
				variant="secondary"
				>{{ $t('noadvertising') }}</b-badge
			>
		</template>

		<template #CurlHaveCzAdvertisement="list">
			<b-badge
				@click="changeCzAdvertisement(list.row)"
				class="cursor-pointer"
				v-if="list.row.CurlHaveCzAdvertisement"
				variant="success"
				>{{ $t('advertising') }}</b-badge
			>

			<b-badge
				@click="changeCzAdvertisement(list.row)"
				class="cursor-pointer gray-icon"
				v-if="!list.row.CurlHaveCzAdvertisement"
				variant="secondary"
				>{{ $t('noadvertising') }}</b-badge
			>
		</template>

		<template #CurlHavePlAdvertisement="list">
			<b-badge
				@click="changePlAdvertisement(list.row)"
				class="cursor-pointer"
				v-if="list.row.CurlHavePlAdvertisement"
				variant="success"
				>{{ $t('advertising') }}</b-badge
			>

			<b-badge
				@click="changePlAdvertisement(list.row)"
				class="cursor-pointer gray-icon"
				v-if="!list.row.CurlHavePlAdvertisement"
				variant="secondary"
				>{{ $t('noadvertising') }}</b-badge
			>
		</template>

		<template #CurlHaveHuAdvertisement="list">
			<b-badge
				@click="changeHuAdvertisement(list.row)"
				class="cursor-pointer"
				v-if="list.row.CurlHaveHuAdvertisement"
				variant="success"
				>{{ $t('advertising') }}</b-badge
			>

			<b-badge
				@click="changeHuAdvertisement(list.row)"
				class="cursor-pointer gray-icon"
				v-if="!list.row.CurlHaveHuAdvertisement"
				variant="secondary"
				>{{ $t('noadvertising') }}</b-badge
			>
		</template>

		<template #CurlHaveDeAdvertisement="list">
			<b-badge
				@click="changeDeAdvertisement(list.row)"
				class="cursor-pointer"
				v-if="list.row.CurlHaveDeAdvertisement"
				variant="success"
				>{{ $t('advertising') }}</b-badge
			>

			<b-badge
				@click="changeDeAdvertisement(list.row)"
				class="cursor-pointer gray-icon"
				v-if="!list.row.CurlHaveDeAdvertisement"
				variant="secondary"
				>{{ $t('noadvertising') }}</b-badge
			>
		</template>

		<template #CurlType="list">{{ formatType(list.row.CurlType) }}</template>

		<template #CurlHaveEnAdvertisement="list">
			<b-badge
				@click="changeEnAdvertisement(list.row)"
				class="cursor-pointer"
				v-if="list.row.CurlHaveEnAdvertisement"
				variant="success"
				>{{ $t('advertising') }}</b-badge
			>

			<b-badge
				@click="changeEnAdvertisement(list.row)"
				class="cursor-pointer gray-icon"
				v-if="!list.row.CurlHaveEnAdvertisement"
				variant="secondary"
				>{{ $t('noadvertising') }}</b-badge
			>
		</template>

		<template #SeoArticlesSkCount="list">
			<div class="d-flex justify-content-between">
				<label>{{ list.row.SeoArticlesSkCount }}</label>
				<label>&nbsp;</label>
				<b-badge
					@click="skStateDone(list.row)"
					class="cursor-pointer"
					v-if="list.row.SetSkToWrite > list.row.SeoArticlesSkCount"
					variant="success"
					>{{ $t('fortyping') }}</b-badge
				>

				<b-badge
					@click="skStateSet(list.row)"
					class="cursor-pointer gray-icon"
					v-if="list.row.SetSkToWrite <= list.row.SeoArticlesSkCount"
					variant="secondary"
					>{{ $t('2781') }}</b-badge
				>
			</div>
		</template>

		<template #SeoArticlesCzCount="list">
			<div class="d-flex justify-content-between">
				<label>{{ list.row.SeoArticlesCzCount }}</label>
				<label>&nbsp;</label>
				<b-badge
					@click="czStateDone(list.row)"
					class="cursor-pointer"
					v-if="list.row.SetCzToWrite > list.row.SeoArticlesCzCount"
					variant="success"
					>{{ $t('fortyping') }}</b-badge
				>

				<b-badge
					@click="czStateSet(list.row)"
					class="cursor-pointer gray-icon"
					v-if="list.row.SetCzToWrite <= list.row.SeoArticlesCzCount"
					variant="secondary"
					>{{ $t('2781') }}</b-badge
				>
			</div>
		</template>

		<template #SeoArticlesPlCount="list">
			<div class="d-flex justify-content-between">
				<label>{{ list.row.SeoArticlesPlCount }}</label>
				<label>&nbsp;</label>
				<b-badge
					@click="plStateDone(list.row)"
					class="cursor-pointer"
					v-if="list.row.SetPlToWrite > list.row.SeoArticlesPlCount"
					variant="success"
					>{{ $t('fortyping') }}</b-badge
				>

				<b-badge
					@click="plStateSet(list.row)"
					class="cursor-pointer gray-icon"
					v-if="list.row.SetPlToWrite <= list.row.SeoArticlesPlCount"
					variant="secondary"
					>{{ $t('2781') }}</b-badge
				>
			</div>
		</template>

		<template #SeoArticlesHuCount="list">
			<div class="d-flex justify-content-between">
				<label>{{ list.row.SeoArticlesHuCount }}</label>
				<label>&nbsp;</label>
				<b-badge
					@click="huStateDone(list.row)"
					class="cursor-pointer"
					v-if="list.row.SetHuToWrite > list.row.SeoArticlesHuCount"
					variant="success"
					>{{ $t('fortyping') }}</b-badge
				>

				<b-badge
					@click="huStateSet(list.row)"
					class="cursor-pointer gray-icon"
					v-if="list.row.SetHuToWrite <= list.row.SeoArticlesHuCount"
					variant="secondary"
					>{{ $t('2781') }}</b-badge
				>
			</div>
		</template>

		<template #SeoArticlesDeCount="list">
			<div class="d-flex justify-content-between">
				<label>{{ list.row.SeoArticlesDeCount }}</label>
				<label>&nbsp;</label>
				<b-badge
					@click="deStateDone(list.row)"
					class="cursor-pointer"
					v-if="list.row.SetDeToWrite > list.row.SeoArticlesDeCount"
					variant="success"
					>{{ $t('fortyping') }}</b-badge
				>

				<b-badge
					@click="deStateSet(list.row)"
					class="cursor-pointer gray-icon"
					v-if="list.row.SetDeToWrite <= list.row.SeoArticlesDeCount"
					variant="secondary"
					>{{ $t('2781') }}</b-badge
				>
			</div>
		</template>

		<template #SeoArticlesEnCount="list">
			<div class="d-flex justify-content-between">
				<label>{{ list.row.SeoArticlesEnCount }}</label>
				<label>&nbsp;</label>
				<b-badge
					@click="enStateDone(list.row)"
					class="cursor-pointer"
					v-if="list.row.SetEnToWrite > list.row.SeoArticlesEnCount"
					variant="success"
					>{{ $t('fortyping') }}</b-badge
				>

				<b-badge
					@click="enStateSet(list.row)"
					class="cursor-pointer gray-icon"
					v-if="list.row.SetEnToWrite <= list.row.SeoArticlesEnCount"
					variant="secondary"
					>{{ $t('2781') }}</b-badge
				>
			</div>
		</template>

		<template #CountryId="list">
			<country-flag :countryId="Number(list.row.CountryId)"></country-flag>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import { setDropdownOptions } from '@/components/general/utils';
import serviceEnums from '@/services/service/enums.service';
import serviceCommon from '../../services/service/common.service';
import apiCommon from '../../services/api/common.api';

import { tableColumns } from './url-catalog.table-data';

export default {
	name: 'curlTable',

	components: {
		TablePage,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'CatalogUrl',

			TypeDict: serviceEnums.getEnumDict('urlType'),
		};
	},

	async created() {
		this.columns = this.prepareColumns(tableColumns);
	},

	methods: {
		prepareColumns(columns) {
			let values = serviceEnums.getEnumForDropdown('advertising', true);
			columns = setDropdownOptions(columns, 'CurlHaveSkAdvertisement', values);
			columns = setDropdownOptions(columns, 'CurlHaveCzAdvertisement', values);
			columns = setDropdownOptions(columns, 'CurlHavePlAdvertisement', values);
			columns = setDropdownOptions(columns, 'CurlHaveHuAdvertisement', values);
			columns = setDropdownOptions(columns, 'CurlHaveDeAdvertisement', values);
			columns = setDropdownOptions(columns, 'CurlHaveEnAdvertisement', values);

			values = serviceEnums.getEnumForDropdown('urlSeoState', true);
			columns = setDropdownOptions(columns, 'SeoArticlesSkCount', values);
			columns = setDropdownOptions(columns, 'SeoArticlesCzCount', values);
			columns = setDropdownOptions(columns, 'SeoArticlesPlCount', values);
			columns = setDropdownOptions(columns, 'SeoArticlesHuCount', values);
			columns = setDropdownOptions(columns, 'SeoArticlesDeCount', values);
			columns = setDropdownOptions(columns, 'SeoArticlesEnCount', values);

			values = serviceEnums.getEnumForDropdown('urlType', true);
			columns = setDropdownOptions(columns, 'CurlType', values);

			return columns;
		},

		changeSkAdvertisement(row) {
			row.CurlHaveSkAdvertisement = !row.CurlHaveSkAdvertisement;
			this.updateTableRow(row);
		},
		changeCzAdvertisement(row) {
			row.CurlHaveCzAdvertisement = !row.CurlHaveCzAdvertisement;
			this.updateTableRow(row);
		},

		changePlAdvertisement(row) {
			row.CurlHavePlAdvertisement = !row.CurlHavePlAdvertisement;
			this.updateTableRow(row);
		},

		changeHuAdvertisement(row) {
			row.CurlHaveHuAdvertisement = !row.CurlHaveHuAdvertisement;
			this.updateTableRow(row);
		},
		changeEnAdvertisement(row) {
			row.CurlHaveEnAdvertisement = !row.CurlHaveEnAdvertisement;
			this.updateTableRow(row);
		},
		changeDeAdvertisement(row) {
			row.CurlHaveDeAdvertisement = !row.CurlHaveDeAdvertisement;
			this.updateTableRow(row);
		},
		enStateDone(row) {
			row.SetEnToWrite = row.SeoArticlesEnCount;
			this.updateTableRow(row);
		},
		enStateSet(row) {
			row.SetEnToWrite = row.SeoArticlesEnCount + 1;
			this.updateTableRow(row);
		},
		deStateDone(row) {
			row.SetDeToWrite = row.SeoArticlesDeCount;
			this.updateTableRow(row);
		},
		deStateSet(row) {
			row.SetDeToWrite = row.SeoArticlesDeCount + 1;
			this.updateTableRow(row);
		},
		plStateDone(row) {
			row.SetPlToWrite = row.SeoArticlesPlCount;
			this.updateTableRow(row);
		},
		plStateSet(row) {
			row.SetPlToWrite = row.SeoArticlesPlCount + 1;
			this.updateTableRow(row);
		},
		huStateDone(row) {
			row.SetHuToWrite = row.SeoArticlesHuCount;
			this.updateTableRow(row);
		},
		huStateSet(row) {
			row.SetHuToWrite = row.SeoArticlesHuCount + 1;
			this.updateTableRow(row);
		},
		czStateDone(row) {
			row.SetCzToWrite = row.SeoArticlesCzCount;
			this.updateTableRow(row);
		},
		czStateSet(row) {
			row.SetCzToWrite = row.SeoArticlesCzCount + 1;
			this.updateTableRow(row);
		},
		skStateDone(row) {
			row.SetSkToWrite = row.SeoArticlesSkCount;
			this.updateTableRow(row);
		},
		skStateSet(row) {
			row.SetSkToWrite = row.SeoArticlesSkCount + 1;
			this.updateTableRow(row);
		},
		updateTableRow(row) {
			apiCommon.putEntity(row, this.controllerName);
		},

		refresh() {
			this.tableKey += 1;
		},
		formatType(value) {
			if (value > -1) {
				return this.TypeDict[value].Text;
			} else {
				return '-';
			}
		},
		handleCustomCountryFilter() {
			this.globalCountryId = null;
			this.handleCustomFilter();
		},
		handleCustomFilter() {
			serviceCommon.setCustomFilter(this.filters, this.$options.name);
		},
		redirectToDetail(itemId = 0) {
			this.$router.push({
				name: links.urlCatalogDetailName,
				params: { UrlCatalogId: itemId },
			});
		},
	},
};
</script>

<style lang="scss">
.curlTable {
	.VueTables__filters-row > th:nth-child(1) {
		min-width: 55px;
		width: 55px;
	}
	.VueTables__filters-row > th:nth-child(8) {
		max-width: 100px;
	}
	.cursor-pointer {
		cursor: pointer;
		height: 15px;
	}
	.gray-icon {
		background: var(--mu-icongray);
	}
}
</style>
