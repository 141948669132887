export const tableColumns = [
	{
		model: 'CurlName',
		i18n: 'url',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CurlCountObjectsCatalog',
		i18n: 'urlname2776',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'CurlHaveSkAdvertisement',
		i18n: 'hasanadsk',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'CurlHaveCzAdvertisement',
		i18n: 'hasanadcz',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'CurlHavePlAdvertisement',
		i18n: 'hasanadpl',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'CurlHaveHuAdvertisement',
		i18n: 'hasanadhu',
		hidden: true,
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'CurlHaveDeAdvertisement',
		i18n: 'hasanadde',
		hidden: true,
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'CurlHaveEnAdvertisement',
		i18n: 'hasanaden',
		hidden: true,
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'SeoArticlesSkCount',
		i18n: 'numberofarticle',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'SeoArticlesCzCount',
		i18n: 'numberofarticle2778',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'SeoArticlesPlCount',
		i18n: 'numberofarticle2779',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'SeoArticlesHuCount',
		i18n: 'numberofarticle2792',
		hidden: true,
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'SeoArticlesDeCount',
		i18n: 'numberofarticle2791',
		hidden: true,
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'SeoArticlesEnCount',
		i18n: 'numberofarticle2790',
		hidden: true,
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'CurlType',
		i18n: 'typeofurl',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
		filter: 'country',
	},
];
